import $ from 'jquery'
window.jQuery = $;
window.$ = $;

/*
ON LOAD PAGE FUNCTION
*/

import Swiper from 'swiper/swiper-bundle.esm.browser';
import Inputmask from "inputmask";
import lightbox from "lightbox2/dist/js/lightbox";

jQuery( window ).on( 'load', function() {

    $('body').removeClass('is-load');

} );

/*
INITIALIZATION FUNCTIONS
*/

jQuery( document ).ready( function( $ ) {

    lightbox.option()

    let im = new Inputmask("+7 (999)999-99-99");

    im.mask('.phone-mask');



    $(".last-line").on("click","a", function (e) {
        let id  = $(this).attr('href'),
            top = document.getElementById(id.slice(1)).offsetTop;

        $('body,html').animate({scrollTop: top + 1}, 1000);
    });


    $(".sub-menu").on("click","a", function (e) {
            e.preventDefault()
            let id  = $(this).attr('href'),
                top = document.getElementById(id.slice(1)).offsetTop;
            $('body,html').animate({scrollTop: top + 1}, 1000);

    });




    let galleryThumbs = new Swiper('.team-building--previews', {
        spaceBetween: 10,
        slidesPerView: 3,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slidesPerColumn: 2,
        pagination: {
            clickable: true,
        },
    });
    new Swiper('.track-video--slider', {
        slidesPerView: 1,
        loop:true,
        navigation: {
            nextEl: '.track-video--next',
            prevEl: '.track-video--prev',
        },
        pagination: {
            clickable: true,
        },

    });
    new Swiper('.team-building--slider', {
        spaceBetween: 15,
         slidesPerView: 1,
        navigation: {
            nextEl: '.team-building--slider-next',
            prevEl: '.team-building--slider-prev',
        },
        thumbs: {
            swiper: galleryThumbs,
        },
        pagination: {
            clickable: true,
        },

    });
    new Swiper('.contests--slider', {
        slidesPerView: 3,
        spaceBetween: 30,
        cssMode: true,
        breakpoints: {
            320: {
                slidesPerView: 1.3,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 2,
                cssMode: false,
                spaceBetween: 10,
            },
            1024: {
                slidesPerView: 3,
            }
        }
    });

    let galleryTop = new Swiper('.gallery-thumbs', {
        spaceBetween: 15,
        slidesPerView: 3,
        loop: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        pagination: {
            clickable: true,
        },
    });
     new Swiper('.gallery-top', {
        spaceBetween: 10,
        loop: true,
        navigation: {
            nextEl: '.gallery-top-next',
            prevEl: '.gallery-top-prev',
        },
        thumbs: {
            swiper: galleryTop,
        },
         pagination: {
             clickable: true,
         },
    })
    new Swiper('.slider-routes', {
        slidesPerView: 'auto',
        loop: true,
        navigation: {
            nextEl: '.slider-routes-next',
            prevEl: '.slider-routes-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            480: {
                slidesPerView: 'auto',
            },
            1024: {

            }
        }
    });

    let lastTour = new Swiper('.last-tour-thumbs', {
        spaceBetween: 15,
        slidesPerView: 3,
        loop: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        pagination: {
            clickable: true,
        },
    });
    new Swiper('.last-tour-top', {
        spaceBetween: 10,
        loop: true,
        navigation: {
            nextEl: '.last-tour-top-next',
            prevEl: '.last-tour-top-prev',
        },
        thumbs: {
            swiper: lastTour,
        },
        pagination: {
            clickable: true,
        },
    });

    let thankYouLetter = function(){
        $('.thank-letter').removeClass('is-active')
        $('body').removeClass('no-scroll')

    };
    document.addEventListener( 'wpcf7mailsent', function( event ) {
        $('.thank-letter').addClass('is-active')
        $('.popup-content--mouseleave').parent('div').removeClass('is-active')

         setTimeout(thankYouLetter, 2000);

    })
    $('.articles-content-more span').on('click', function (){
        $('.articles-content').toggleClass('opened')
        $(this).toggleClass('is-active').promise().done(function (e) {
            e.html((e.hasClass('is-active') ? 'Скрыть' : 'Читать далее'))
        });
    })

    $('.text-section span').on('click',function (){
        $('.text-section .hidden-text').toggleClass('is-active')
        $(this).toggleClass('is-active').promise().done(function (e) {
            e.html((e.hasClass('is-active') ? 'Скрыть' : 'Читать далее'))
        });
    })
    $('.popup-content .close').on('click', function (){
        $('.popup-bg').removeClass('is-active')
        $('body').removeClass('no-scroll')
        $('.popup-content iframe').attr('src', '')
    })
    $('.play-video').on('click', function (){
        let thisSrc = $(this).find('img').attr('data-video');
        $('.popup-content iframe').attr('src', 'https://www.youtube.com/embed/' + thisSrc)
        $('body').addClass('no-scroll')
        $('.popup-bg').addClass('is-active')
    })
    $('.order-button').on('click', function (){
        $('body').addClass('no-scroll')
        $('.order-popup-bg').addClass('is-active')
    })
    $('.order-popup-bg .close').on('click', function (){
        $('body').removeClass('no-scroll')
        $('.order-popup-bg').removeClass('is-active')
    })
    $('.order-alcove').on('click', function (){
        $('body').addClass('no-scroll')
        $('.alcove-popup-bg').addClass('is-active')
    })
    $('.alcove-popup-bg .close').on('click', function (){
        $('body').removeClass('no-scroll')
        $('.alcove-popup-bg').removeClass('is-active')
    })
    $('.order-romantick').on('click', function (){
        $('body').addClass('no-scroll')
        $('.romantick-popup-bg').addClass('is-active')
    })
    $('.romantick-popup-bg .close').on('click', function (){
        $('body').removeClass('no-scroll')
        $('.romantick-popup-bg').removeClass('is-active')
    })
    $('.order-tour').on('click', function (){
        $('body').addClass('no-scroll')
        $('.tour-popup-bg').addClass('is-active')
    })
    $('.tour-popup-bg .close').on('click', function (){
        $('body').removeClass('no-scroll')
        $('.tour-popup-bg').removeClass('is-active')
    })
    $('.order-sertificat').on('click', function (){
        $('body').addClass('no-scroll')
        $('.certificates-popup-bg').addClass('is-active')
    })
    $('.certificates-popup-bg .close').on('click', function (){
        $('body').removeClass('no-scroll')
        $('.certificates-popup-bg').removeClass('is-active')
    })
    $('.order-routes').on('click', function (){
        $('body').addClass('no-scroll')
        $('.routes-popup-bg').addClass('is-active')
    })
    $('.routes-popup-bg .close').on('click', function (){
        $('body').removeClass('no-scroll')
        $('.routes-popup-bg').removeClass('is-active')
    })
    $('.init-map').on('mouseenter', function () {
        if (!$(this).hasClass('init-map')) {
            return
        }
        $(this).removeClass('init-map');

        loadScript('https://api-maps.yandex.ru/2.1/?lang=ru_RU', function () {
            initMap();
        })
    });

    function initMap() {
        ymaps.ready(() => {
            let myMap = new ymaps.Map('map', {
                    center: [55.4111034,37.7803663],
                    zoom: 12,
                    controls:[],
                }, {
                    searchControlProvider: 'yandex#search'
                }),
                myPlacemark = new ymaps.Placemark([55.4111034,37.7803663], {
                    iconContent: "Клуб газуй",
                    balloonContentFooter: "Клуб газуй",
                    hintContent: "Клуб газуй"
                });

            myMap.geoObjects.add(myPlacemark)
        })
    }
    function loadScript(url, callback) {
        let script = document.createElement("script")
        script.type = "text/javascript";
        if (script.readyState) {  // only required for IE <9
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {  //Others
            script.onload = function () {
                callback();
            };
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    }


} );

/*
ON SCROLL PAGE FUNCTIONS
*/

jQuery( window ).on( 'scroll', function() {

    let $win = $(window),
       $footerRepairs = $('.romance-content .order-romantick');
    if($footerRepairs.length > 0 ){
        if($win.scrollTop() + $win.height() >= $footerRepairs.offset().top) {
            $('section.romance').addClass('black')
        }else{
            $('section.romance').removeClass('black')
        }
    }

    let scrollPos = window.pageYOffset;
    if (scrollPos > 500) {
        $('.corporate-fixed').addClass('is-active')
        $('.go-to--top').addClass('is-active')
    } else {
        $('.corporate-fixed').removeClass('is-active')
        $('.go-to--top').removeClass('is-active')
    }
    let $footer = $('footer');
    if($footer.length > 0 ){
        if($win.scrollTop() + $win.height() >= $footer.offset().top  ) {
            $('.corporate-fixed').removeClass('is-active')
            $('.go-to--top').removeClass('is-active')
        }else{

        }
    }

} );

$(document).mouseup(function (e){
    let block = $(".popup-content--mouseleave"),
        block2 = $('.popup-content--mouseleave'),
        block3 = $(".popup-content--mouseleave"),
        block4 = $(".popup-content--mouseleave"),
        block5 = $(".popup-content--mouseleave"),
        block6 = $(".popup-content--mouseleave"),
        block7 = $(".popup-content--mouseleave");
    if (!block.is(e.target)
        && block.has(e.target).length === 0) {
        $('.popup-bg').removeClass('is-active')
        $('body').removeClass('no-scroll')
        $('.popup-content iframe').attr('src', '')
    }
    if (!block2.is(e.target)
        && block2.has(e.target).length === 0) {
        $('body').removeClass('no-scroll')
        $('.order-popup-bg').removeClass('is-active')
    }
    if (!block3.is(e.target)
        && block3.has(e.target).length === 0) {
        $('body').removeClass('no-scroll')
        $('.romantick-popup-bg').removeClass('is-active')
    }
    if (!block4.is(e.target)
        && block4.has(e.target).length === 0) {
        $('body').removeClass('no-scroll')
        $('.alcove-popup-bg').removeClass('is-active')
    }
    if (!block5.is(e.target)
        && block5.has(e.target).length === 0) {
        $('body').removeClass('no-scroll')
        $('.certificates-popup-bg').removeClass('is-active')
    }
    if (!block6.is(e.target)
        && block6.has(e.target).length === 0) {
        $('body').removeClass('no-scroll')
        $('.tour-popup-bg').removeClass('is-active')
    }
    if (!block7.is(e.target)
        && block7.has(e.target).length === 0) {
        $('body').removeClass('no-scroll')
        $('.routes-popup-bg').removeClass('is-active')
    }
});


